<template>
    <div
        ref="document"
        class="document"
        :class="[showAttribution, $fonts.getFontClass(style.font)]"
        :style="{
            color: style.color + '!important',
            fontSize: style.font_size + 'px',
        }"
    >
        <div class="image-sticker-area">
            <StickerImage
                v-for="image in pageImages"
                :key="image.id"
                :properties="image"
                :disabled="Boolean(document.is_published)"
                :image-id="image.objectId"
                @movePage="movePage"
            />
        </div>
        <div class="printable-area" :class="printclass" :style="{ height: '10in' }">
            <header
                v-if="!hideHead || omniStudent"
                id="docHeader"
                ref="docHeader"
                class="position-relative headers"
                :class="{ pointer: !document.is_published, 'complete-document-header': showStudentInfo }"
                :style="headerStyles"
                @click="goto('goto-id-header', 'goto-container')"
            >
                <span :id="'anchor-docHeader'" class="position-absolute" style="top: -100px"></span>

                <div
                    v-if="document.title_visible || document.student_info_visible"
                    class="document-header"
                    :class="{ 'pb-0': !showStudentInfo }"
                    :style="{ color: style.color, marginBottom: getTitleBottomPaddingHeight }"
                >
                    <div
                        v-if="hasTitleBox"
                        id="doc-preview-title"
                        ref="docTitle"
                        class="document-title pr-2 preserve-whitespace"
                        :class="$fonts.getFontClass(document.title_font)"
                        :style="{
                            color: document.title_color,
                            fontSize: getTitleFontSize,
                            lineHeight: 1.25,
                            width: title_should_span_fully ? '100%' : '60%',
                            overflowWrap: `break-word`,
                            wordBreak: `break-word`,
                        }"
                        @click="getDocumentTitleClick"
                        v-html="document.title"
                    ></div>

                    <div
                        v-if="hasStudentInfoBox"
                        id="student-doc-preview"
                        ref="docStudentInfo"
                        class="document-student pl-2"
                        :style="{
                            color: document.student_info_color,
                            fontSize: getStudenInfoFontSize,
                            fontFamily: documentFonts.student_info_font,
                            width: title_should_span_fully ? '0' : '40%',
                        }"
                    >
                        <table class="student-fields">
                            <tr
                                v-for="(student_field, indexF) in document.student_fields"
                                v-show="student_field"
                                :key="'student-info-' + indexF"
                            >
                                <td v-if="student_field" @click="studentInfoClick(indexF)">
                                    <span class="preserve-whitespace" v-text="`${student_field.trim()}:`"></span>
                                </td>
                                <td class="student-field-underline" @click="studentInfoClick(indexF)"></td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div
                    v-if="hasInstructionBox"
                    v-show="instructions.visible"
                    :id="`instructions`"
                    :ref="`instructions-${index}`"
                    class="document-instructions position-relative"
                    :class="$fonts.getFontClass(instructionStyle.font)"
                    :style="{
                        borderColor: instructionStyle.border_color,
                        color: instructionStyle.color,
                        fontSize: getInstructionFontSize,
                        borderStyle: instructionStyle.border_style,
                        borderWidth: instructionStyle.border_width + 'px',
                        padding: instructionStyle.border_width === 0 ? '1em 0' : '1em',
                    }"
                    @click="getDocumentInstructionClick"
                >
                    <div class="preserve-whitespace" v-html="instructions.content"></div>
                    <span :id="`anchor-instructions-${index}`" class="position-absolute" style="top: -100px"></span>
                </div>
            </header>
            <slot></slot>
        </div>
    </div>
</template>

<script>
import StickerImage from '../components/widgets/StickerImage.vue'
import { mapGetters, mapState } from 'vuex'
import WithDocumentHeader from '../mixins/WithDocumentHeader'
import { defaultStyle, initialize } from '../store/helpers/documentHelpers'

export default {
    name: 'Document',
    components: { StickerImage },
    mixins: [WithDocumentHeader],
    props: {
        printclass: { type: String, default: '' },
        isCallList: {
            type: Boolean,
            default: false,
        },
        cardsCount: {
            type: Number,
            default: -1,
        },
        documentFirstPageHeight: {
            type: [Number, String],
            default: 'auto',
        },
    },
    data() {
        return {
            delete_confirmation: false,
            instructionHeight: 0,
            flashcardHeaderHeight: 2,
            secondPageHeight: '2in',
            displayLogo: true,
            preventPrint: false,
            flashcardFirstPageHeight: 0,
            subdomain: window.subdomain ?? 'default',
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            documentFonts: 'document/documentFonts',
            isWorksheet: 'document/isWorksheet',
            worksheetImages: 'document/worksheetImages',
            flashcardImages: 'document/flashcardImages',
            bingoImages: 'document/bingoImages',
            entityType: 'document/entityType',
            zoomRatio: 'document/getScaleRatio',
        }),
        ...mapState(['user']),
        images() {
            if (this.entityType === 'worksheet') return this.worksheetImages
            if (this.entityType === 'flashcard') return this.flashcardImages
            if (this.entityType === 'bingo') return this.bingoImages
            return []
        },
        pageImages() {
            if (this.isCallList) return []
            if (this.images?.length)
                return this.images.filter((i) => this.entityType === 'bingo' || i.docIndex === this.index).reverse()
            return []
        },
        showAttribution() {
            // Check if there is an override first (Like when trying to generate white label PDF).
            if (this.displayLogo) {
                if (typeof window.force_white_label != 'undefined') {
                    return window.force_white_label ? '' : 'attribution ' + this.subdomain
                }
                return this.user.user.white_label ? '' : 'attribution ' + this.subdomain
            } else {
                return ''
            }
        },
        title_font_size() {
            return this.$store.state.document.title_font_size
        },
        title_should_span_fully() {
            let emptyFields = true
            if (this.document.student_fields) {
                for (let i = 0; i < this.document.student_fields.length; i++) {
                    if (this.document.student_fields[i]) {
                        emptyFields = false
                    }
                }
            }
            return !this.document.student_info_visible || this.document.student_fields.length <= 0 || emptyFields
        },
        getDocumentHeaderHeight() {
            if (this.document.entity_type === 'flashcard')
                return this.index === 1 && this.flashcard.page_setup.flashcard_print_type === 'double-side'
                    ? `${this.documentFirstPageHeight}in`
                    : 'auto'
            else if (this.document.entity_type === 'bingo') return '2in'
            else return 'auto'
        },
        getTitleFontSize() {
            if (this.usesHeaderAutoResize) return `${this.titleFontSize}px`
            if (this.isWorksheet) return `${this.document.title_font_size}px`

            return this.shouldResetHeaderFontSizes && this.document.title_font_size >= this.titleFontSize
                ? `${initialize.title_font_size}px`
                : `${this.document.title_font_size}px`
        },
        getStudenInfoFontSize() {
            if (this.usesHeaderAutoResize) return `${this.studentInfoFontSize}px`
            if (this.isWorksheet) return `${this.document.student_info_font_size}px`

            return this.shouldResetHeaderFontSizes && this.document.student_info_font_size >= this.studentInfoFontSize
                ? `${initialize.student_info_font_size}px`
                : `${this.document.student_info_font_size}px`
        },
        getInstructionFontSize() {
            if (this.usesHeaderAutoResize) return `${this.instructionFontSize}px`
            if (this.isWorksheet) return `${this.instructionStyle.font_size}px`

            return this.shouldResetHeaderFontSizes && this.instructionStyle.font_size >= this.instructionFontSize
                ? `${defaultStyle.font_size}px`
                : `${this.instructionStyle.font_size}px`
        },
        headerStyles() {
            if (this.usesHeaderAutoResize) {
                return {
                    height: `${this.headerHeightInPixels}px`,
                    maxHeight: `${this.headerHeightInPixels}px`,
                    overflow: `hidden`,
                }
            }

            return {
                height: `${this.getDocumentHeaderHeight}`,
                maxHeight: `${this.getDocumentHeaderHeight}`,
            }
        },
        getInstructionMaxHeight() {
            if (this.usesHeaderAutoResize) {
                return `${this.instructionMaxHeight}px`
            }

            return 'auto'
        },
        shouldResetHeaderFontSizes() {
            return this.document.is_published && this.originalFlashcardType === '3x5'
        },
    },
    watch: {
        'flashcard.instruction.style.font_size': {
            immediate: true,
            handler() {
                let elem = this.$refs['instructions-' + this.index]
                if (typeof elem !== 'undefined') {
                    this.instructionHeight = elem.clientHeight / 96
                    if (this.instructionHeight > 0) {
                        this.$emit('instructionHeightChanged', this.instructionHeight)
                    }
                }
            },
        },
        'user.user.white_label': {
            immediate: true,
            handler(val) {
                this.displayLogo = !!!val
            },
        },
    },
    mounted() {
        let docHeader = document.getElementsByClassName('headers')
        if (docHeader.length) {
            this.secondPageHeight = document.getElementsByClassName('headers')[0]?.style.height
        }
        if (this.document.entity_type === 'bingo' || this.document.entity_type === 'flashcard') {
            let routeelem = window.location.pathname.split('/')[3]
            this.displayLogo = routeelem !== 'edit'
        }
        let elem = this.$refs['instructions-' + this.index]
        if (typeof elem !== 'undefined') {
            let size = elem.clientHeight / 96
            if (size > 1) {
                this.instructionHeight = size
            }
            elem.addEventListener('DOMSubtreeModified', (event) => {
                let size = event.target.clientHeight
                if (size > 1) {
                    this.instructionHeight = size / 96
                    this.$emit('instructionHeightChanged', this.instructionHeight)
                }
            })
        }

        if (this.entityType === 'flashcard') {
            window.addEventListener('load', () => {
                this.watchForHeaderHeight()
            })
        }
    },
    methods: {
        goto(refName, containerRef) {
            this.$emit('goto', { ref: refName, container: containerRef })
        },
        movePage(image, toNext) {
            if (this.entityType === 'bingo') return

            const action = this.entityType === 'worksheet' ? 'document/updateImage' : 'document/updateFlashcardImage'

            const pages = document.getElementsByClassName('render-page')
            if (toNext && image.docIndex < pages.length - 1) {
                image.docIndex += 1
                image.y = 2
                this.$store.dispatch(action, image)
            }
            if (!toNext && image.docIndex > 0) {
                image.docIndex -= 1
                image.y = this.$refs.document.getBoundingClientRect().height / this.zoomRatio - image.height - 2
                this.$store.dispatch(action, image)
            }
        },
        getDocumentTitleClick() {
            this.$store.dispatch('document/setWidgetStatus', {
                openHeader: true,
                focusedItem: undefined,
            })
        },
        getDocumentInstructionClick() {
            this.$store.dispatch('document/setWidgetStatus', {
                openInstructions: true,
                focusedItem: undefined,
            })
        },
        studentInfoClick(index) {
            this.$store.dispatch('document/setWidgetStatus', {
                openHeader: true,
                openStudentInfo: true,
                focusedItem: {
                    index,
                },
            })
        },
        watchForHeaderHeight() {
            let headerHeight = document.getElementsByClassName('headers')[0].clientHeight / 96
            if (headerHeight > 0) {
                this.flashcardFirstPageHeight = headerHeight
                this.$store.dispatch('document/setFlashcardHeaderHeight', this.flashcardFirstPageHeight)
            }
            let el = document.getElementsByClassName('headers')[0]
            const heightObserver = new ResizeObserver(() => {
                let headerHeight = document.getElementsByClassName('headers')[0]?.clientHeight / 96
                if (headerHeight > 0) {
                    this.flashcardFirstPageHeight = headerHeight
                    this.$store.dispatch('document/setFlashcardHeaderHeight', this.flashcardFirstPageHeight)
                }
            })

            heightObserver.observe(el)
        },
    },
}
</script>

<style lang="scss" scoped>
@import 'Scss/base.scss';
.document {
    &.show_answer_key:before {
        position: absolute;
        content: 'ANSWER KEY';
        text-align: center;
        top: 0px;
        left: 0px;
        right: 0px;
        padding: 10px;
        font-size: 24px;
        color: $danger;
        font-family: $font-family-base;
    }
    .document-actions {
        opacity: 0;
        padding: 1.75in;
        box-sizing: border-box;
        background-color: rgba(255, 255, 255, 0.8);
        position: absolute;
        width: 8.5in;
        height: 11in;
        top: -1000000px;
        left: -1000000px;
        overflow: hidden;
        transition: opacity 0.15s linear;
        font-family: $font-family-base;
    }
    &:hover {
        .document-actions {
            opacity: 1;
            top: 0px;
            left: 0px;
        }
    }
    &.attribution.default,
    &.attribution.worksheets,
    &.attribution.dev-worksheets,
    &.attribution.staging-worksheets {
        &:after {
            content: 'Created with My Worksheet Maker';
        }
    }

    &.attribution {
        &:after {
            content: 'Created with My Worksheet Maker';
            font-family: 'Open Sans', Arial, Helvetica, sans-serif;
            font-size: 14px;
            color: $black;
            padding: 3px;
            border: 1px solid $black;
            position: absolute;
            right: 0.1in;
            bottom: 0.1in;
            background-color: $white;
            background-image: url('img/mark.svg');
            background-repeat: no-repeat;
            background-size: 24px;
            background-position: 10px;
            padding: 10px 10px 10px 40px;
        }
    }
    .document-header {
        display: flex;
        justify-content: space-between;
        .document-title {
            p {
                margin-bottom: 0;
                word-break: break-word;
            }
            width: 60%;
            flex-grow: 1;
        }
        .document-student {
            width: 40%;
            flex-grow: 1;
            table.student-fields {
                width: 100%;
                border: 0px;
                text-align: right;
                tr {
                    border: 0px;
                    td {
                        border: 0px;
                        height: 2em;
                        white-space: nowrap;
                        vertical-align: bottom;
                        &:first-of-type {
                            padding-right: 0.3em;
                        }
                        &.student-field-underline {
                            min-height: 0.4in;
                            border-bottom: 1px solid;
                            width: 100%;
                            width: 2in;
                        }
                    }
                    &:first-of-type {
                        td {
                            height: 1em;
                        }
                    }
                }
            }
        }
    }
    .document-instructions {
        padding: 1em;
        border-width: 2px;
        border-style: solid;
        border-radius: $border-radius;
        p {
            margin-bottom: 0;
        }
    }
}

.loading {
    position: absolute;
    top: 300px;
    left: 300px;
}
</style>
