<template>
    <div
        ref="document"
        class="document-vertical"
        :class="[showAttribution, $fonts.getFontClass(style.font)]"
        :style="{
            color: style.color + '!important',
            fontSize: style.font_size + 'px',
        }"
    >
        <div class="image-sticker-area">
            <StickerImage
                v-for="image in pageImages"
                :key="image.id"
                :properties="image"
                :disabled="Boolean(document.is_published)"
                :image-id="image.objectId"
                @movePage="movePage"
            />
        </div>
        <div class="printable-area" :class="printclass" :style="{ height: `${style.threeByFive.documentHeight}in` }">
            <header
                v-if="!hideHead || omniStudent"
                id="docHeader"
                ref="docHeader"
                class="position-relative headers"
                :class="[{ pointer: !document.is_published }]"
                :style="[{ height: `${headerHeightInPixels}px`, overflow: `hidden` }]"
                @click="goto('goto-id-header', 'goto-container')"
            >
                <span :id="'anchor-docHeader'" class="position-absolute" style="top: -100px"></span>

                <div
                    v-if="document.student_info_visible || document.title_visible"
                    class="document-header"
                    :style="{ color: style.color, paddingBottom: '0px' }"
                >
                    <div
                        v-if="hasTitleBox"
                        ref="docTitle"
                        class="document-title pr-3 preserve-whitespace"
                        :class="$fonts.getFontClass(document.title_font)"
                        :style="{
                            color: document.title_color,
                            fontSize: `${titleFontSize}px`,
                            lineHeight: 1.25,
                        }"
                        @click="getDocumentTitleClick"
                        v-html="document.title"
                    ></div>

                    <div
                        v-if="hasStudentInfoBox"
                        ref="docStudentInfo"
                        class="document-student pl-3"
                        :style="[
                            {
                                color: document.student_info_color,
                                fontSize: `${studentInfoFontSize}px`,
                                fontFamily: documentFonts.student_info_font,
                            },
                        ]"
                    >
                        <table v-if="document.student_fields && showStudentInfo" class="student-fields">
                            <tr
                                v-for="(student_field, indexF) in visibleStudentFields"
                                v-show="student_field"
                                :key="'student-info-' + indexF"
                            >
                                <td v-if="student_field" @click="studentInfoClick(indexF)">
                                    <span class="preserve-whitespace" v-text="`${student_field.trim()}:`"></span>
                                </td>
                                <td class="student-field-underline" @click="studentInfoClick(indexF)"></td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div
                    v-if="hasInstructionBox"
                    v-show="instructions.visible"
                    :id="`instructions-${index}`"
                    :ref="`instructions-${index}`"
                    class="document-instructions text-pre position-relative"
                    :class="$fonts.getFontClass(instructionStyle.font)"
                    :style="[
                        {
                            borderColor: instructionStyle.border_color,
                            color: instructionStyle.color,
                            fontSize: `${instructionFontSize}px`,
                            borderStyle: instructionStyle.border_style,
                            borderWidth: `${instructionStyle.border_width}px`,
                            padding: instructionStyle.border_width === 0 ? '0em 0' : '0.5em',
                            marginTop: `${style.threeByFive.instructionTopMargin}px`,
                        },
                    ]"
                    @click="getDocumentInstructionClick"
                >
                    <div class="preserve-whitespace" v-html="instructions.content"></div>
                    <span :id="`anchor-instructions-${index}`" class="position-absolute" style="top: -100px"></span>
                </div>
            </header>
            <div ref="documentBody">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import StickerImage from '../components/widgets/StickerImage.vue'
import { mapGetters, mapState } from 'vuex'
import WithDocumentHeader from '../mixins/WithDocumentHeader'

export default {
    name: 'DocumentVertical',
    components: { StickerImage },
    mixins: [WithDocumentHeader],
    props: {
        printclass: String,
        isCallList: {
            type: Boolean,
            required: false,
            default: false,
        },
        cardsCount: {
            type: Number,
            required: false,
        },
        documentFirstPageHeight: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            delete_confirmation: false,
            displayLogo: true,
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            documentFonts: 'document/documentFonts',
            isWorksheet: 'document/isWorksheet',
            worksheetImages: 'document/worksheetImages',
            flashcardImages: 'document/flashcardImages',
            bingoImages: 'document/bingoImages',
            entityType: 'document/entityType',
            zoomRatio: 'document/getScaleRatio',
        }),
        ...mapState(['user']),
        images() {
            if (this.entityType === 'worksheet') return this.worksheetImages
            if (this.entityType === 'flashcard') return this.flashcardImages
            if (this.entityType === 'bingo') return this.bingoImages
            return []
        },
        pageImages() {
            if (this.isCallList) return []
            return this.images.filter((i) => this.entityType === 'bingo' || i.docIndex === this.index).reverse()
        },
        showAttribution() {
            //check if there is an override first
            if (typeof window.force_white_label != 'undefined') {
                return window.force_white_label ? '' : 'attribution'
            }
            return this.user.user.white_label ? '' : 'attribution'
        },
        getDocumentHeaderHeight() {
            if (this.flashcard.page_setup.flashcard_print_type === 'double-side' && (this.index === 1 || this.index === 0)) {
                return `${this.headerHeight || 1.5}in`
            }
            return 'auto'
        },
    },
    watch: {
        'user.user.white_label': {
            immediate: true,
            handler(val) {
                this.displayLogo = !!!val
            },
        },
    },
    mounted() {
        if (this.document.entity_type === 'flashcard') {
            let routeelem = window.location.pathname.split('/')[3]
            this.displayLogo = routeelem !== 'edit'
        }

        this.titleFontSize = this.document.title_font_size
        this.instructionFontSize = this.instructionStyle.font_size
        this.studentInfoFontSize = this.document.student_info_font_size

        this.adjustFontSizes()
    },
    methods: {
        goto(refName, containerRef) {
            this.$emit('goto', { ref: refName, container: containerRef })
        },
        movePage(image, toNext) {
            if (this.entityType === 'bingo') return

            const action = this.entityType === 'worksheet' ? 'document/updateImage' : 'document/updateFlashcardImage'

            const pages = document.getElementsByClassName('render-page')
            if (toNext && image.docIndex < pages.length - 1) {
                image.docIndex += 1
                image.y = 2
                this.$store.dispatch(action, image)
            }
            if (!toNext && image.docIndex > 0) {
                image.docIndex -= 1
                image.y = this.$refs.document.getBoundingClientRect().height / this.zoomRatio - image.height - 2
                this.$store.dispatch(action, image)
            }
        },
        getDocumentTitleClick() {
            this.$store.dispatch('document/setWidgetStatus', {
                openHeader: true,
                focusedItem: undefined,
            })
        },
        getDocumentInstructionClick() {
            this.$store.dispatch('document/setWidgetStatus', {
                openInstructions: true,
                focusedItem: undefined,
            })
        },
        studentInfoClick(index) {
            this.$store.dispatch('document/setWidgetStatus', {
                openHeader: true,
                openStudentInfo: true,
                focusedItem: {
                    index,
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@import 'Scss/base.scss';

.document-vertical {
    &.show_answer_key:before {
        position: absolute;
        content: 'ANSWER KEY';
        text-align: center;
        top: 0px;
        left: 0px;
        right: 0px;
        padding: 10px;
        font-size: 24px;
        color: $danger;
        font-family: $font-family-base;
    }

    .document-actions {
        opacity: 0;
        padding: 1.75in;
        box-sizing: border-box;
        background-color: rgba(255, 255, 255, 0.8);
        position: absolute;
        width: 8.5in;
        height: 11in;
        top: -1000000px;
        left: -1000000px;
        overflow: hidden;
        transition: opacity 0.15s linear;
        font-family: $font-family-base;
    }

    &:hover {
        .document-actions {
            opacity: 1;
            top: 0px;
            left: 0px;
        }
    }

    &.attribution {
        &:after {
            font-family: 'Open Sans', Arial, Helvetica, sans-serif;
            font-size: 14px;
            color: $black;
            padding: 3px;
            border: 1px solid $black;
            position: absolute;
            right: 0.1in;
            bottom: 0.1in;
            background-color: $white;
            background-image: url('img/mark.svg');
            background-repeat: no-repeat;
            background-size: 24px;
            background-position: 10px;
            padding: 10px 10px 10px 40px;
        }
    }

    .document-header {
        display: flex;
        justify-content: space-between;

        .document-title {
            p {
                margin-bottom: 0;
                word-break: break-word;
            }

            flex-grow: 1;
        }

        .document-student {
            min-width: 40%;
            flex-grow: 1;

            table.student-fields {
                width: 100%;
                border: 0px;
                text-align: right;

                tr {
                    border: 0px;

                    td {
                        border: 0px;
                        height: 2em;
                        white-space: nowrap;
                        vertical-align: bottom;

                        &:first-of-type {
                            padding-right: 0.3em;
                        }

                        &.student-field-underline {
                            min-height: 0.4in;
                            border-bottom: 1px solid;
                            width: 100%;
                            width: 2in;
                        }
                    }

                    &:first-of-type {
                        td {
                            height: 1em;
                        }
                    }
                }
            }
        }
    }

    .document-instructions {
        padding: 1em;
        border-width: 2px;
        border-style: solid;
        border-radius: $border-radius;

        p {
            margin-bottom: 0;
        }
    }
}
</style>
